import { Config } from '@danone-global/internal/react/core'

import { baseEnvironment } from './environment.base'

export const environment: Config = {
  ...baseEnvironment,

  isProduction: true,

  isDevelopment: false,

  ctProjectKey: 'cz-d2c-prd-danone',

  ctClientId: 'QOpqTxCZ7pX5jn5k0AQ9dkJ3',

  ctClientSecret: 'dQ-A-kiNc0zLXjsVZQeW6dYtGZSk9FWI',

  defaultChannelId: '341d719b-31d6-4796-9d24-d0eb8aff54e1',

  sentryDSN: 'https://26e95ebef18de217c29264c5f256280b@o4506427613577216.ingest.sentry.io/4506433323008000',

  adyen: {
    clientKey: 'live_C2G4BOREOFH7BP3DGJRJEYB5KEDQQ2XH',
    environment: 'live',

    googlePay: {
      environment: 'PRODUCTION',
      gatewayMerchantId: 'DanoneNutricia',
      merchantName: 'Nutricia Nutriklub',
      merchantId: 'BCR2DN4T2XPMJDIP',
    },
  }

}
